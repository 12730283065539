import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import logo from "../../images/safeHarbour/mission.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-primary-500`
const Key = tw.div`font-medium text-gray-700`

export default ({textOnLeft = false}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const statistics = [
    {
      key: "Countries",
      value: "192",
    },
    {
      key: "Hotels",
      value: "479",
    },
    {
      key: "Rooms",
      value: "2093",
    },
    {
      key: "Workers",
      value: "10347",
    }
  ]

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          {/* <Image imageSrc="https://images.unsplash.com/photo-1582564286939-400a311013a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80" /> */}
          <Image imageSrc={logo} alt="logo" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Our Mission</Heading>
            <Description>
            Our mission is to keep commercial and leisure users safe in busy waterways, reduce collisions and minimize the impact of human activities on marine life and local ecosystems. <br/><br/>
            Our first prototype field testing will be in the busy Halifax Harbour, site of the largest human-made explosion before Hiroshima when 2 boats collided during the 1st World War. 
            We were also among the first ports to respond to the S.O.S. from the Titanic. We are no stranger to marine tragedies.  <br/><br/>
            Today, the port is home to the Canadian Coast Guard, Canadian Navy, Cruise Ship Terminals, Container Ship Terminals, fishing boats and other commercial traffic. 
            The Harbour and Northwest Arm boasts ferries, five yacht and sailing clubs, kayak & canoe clubs, dozens of tour boats and other recreational activities. Marine mammals include seals, harbour porpoise and whales. 
              </Description>
            {/* <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>{statistic.value}</Value>
                <Key>{statistic.key}</Key>
              </Statistic>
              ))}
            </Statistics> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
