import React, { useState } from "react";
import tw from "twin.macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-sfBlue text-gray-100`;
const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;


export default (
  {
    subheading = "",
    heading = "Harbour Monitoring",
    description = "Our first prototype field testing will be in the busy Halifax Harbour."
  }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };


  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          <br />
          <br />
        </HeadingContainer>
        {/* <div onClick={handleClick} >
        <iframe style={{ pointerEvents: isActive ? "auto" : "none" }} name="marinetraffic" id="marinetraffic" scrolling="yes" src="https://www.marinetraffic.com/en/ais/embed/zoom:12/centery:44.6510/centerx:-63.56/maptype:1/shownames:false/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:false/remember:false" width="100%" height="600" frameBorder="0">Browser does not support embedded objects.<br />Visit directly <a href="http://www.marinetraffic.com/">www.marinetraffic.com</a></iframe>
        </div> */}
        
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
