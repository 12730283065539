import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import './consultation.css';
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";


const TextContent = tw.div`lg:py-8 text-center md:text-left flex items-center justify-center h-full flex-col mb-8`;

const Heading = tw(
  SectionHeading
)`mt-4 text-left text-lg sm:text-4xl lg:text-2xl text-center md:text-left leading-tight leading-relaxed font-bold `;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed max-w-screen-lg text-secondary-100 `;

const PrimaryButton = styled(PrimaryButtonBase)(props => [ 
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0 bg-sfBlue`,
  props.buttonRounded && tw`rounded-full no-underline`
]);


export default ({
  heading = (
    <>
      Book your 30 minutes Free Consultation with answering a few simple questions.
    </>
  ),
  description = "Let's examine whether (and how) your port or waterway may benefit from SAFE HARBOUR Research & Technologies. ",
  primaryButtonText = "Schedule your consultation",
  primaryButtonUrl = "https://calendly.com/safeharbour/meeting?month",
  buttonRounded = true,
}) => {
  return (
    <div >
    <Container id="background">
          <TextContent>
            <div style={{ color: 'black' }}>
            <Heading><p>{heading}</p></Heading>
            </div>
            <Description>{description}</Description>
            <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}
            onClick={(e)=> {window.location.href="https://calendly.com/safeharbour/meeting?month"}}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
    </Container>
    </div>
  );
};
