import React from "react";
import tw from "twin.macro";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import { SectionDescription } from "components/misc/Typography.js";

import investNS_logo from "../../images/safeHarbour/Partners/Logo_Digital_InvestNS_RGB.png"
import emera_logo from "../../images/safeHarbour/Partners/Emera-ideahub.png"
import seatac_logo from "../../images/safeHarbour/Partners/SEATAC-white-transparent - Copy.png"
import cove_logo from "../../images/safeHarbour/Partners/cove_logo.jpeg"
import mitacs_logo from "../../images/safeHarbour/Partners/mitacs_transparent.png"
import deepsense_logo from "../../images/safeHarbour/Partners/DeepSense logo.png"
import './Partners.css'

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pt-20 lg:pt-24`;
const Description = tw(SectionDescription)`max-w-screen-lg  text-center`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

export default () => {
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Our Partners</HeadingTitle>

        </HeadingInfoContainer>
        <br /><br />
        <div class="logo-container">
          <img class="partners" src={investNS_logo}></img>
          <img class="partners" src={emera_logo}></img>
          {/* <img class="partners" src={cove_logo}></img> */}
          <img class="partners" src={mitacs_logo}></img>
          <img class="partners" src={deepsense_logo}></img>
          <img class="partners" src={seatac_logo}></img>
        </div>

      </Content>
    </Container>
  );
};
