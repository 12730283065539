import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import  './EmailSafeHarbour.css';

const Container = tw(ContainerBase)`bg-sfBlue text-gray-100 -mx-8 -mb-8`
// const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-2`;
// const Content = tw.div`max-w-screen-xl mx-auto pb-2`;
const Row = tw.div`flex items-center justify-center flex-col`
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider m-6`;
const LinksContainer = tw.div`mt-4 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
// const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4 no-underline`;
const Link = tw.a`border-b-2 border-transparent text-white hocus:text-gray-300 pb-1 transition duration-300 mt-2 mx-4 no-underline`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed max-w-screen-lg text-white`;

const SocialLinksContainer = tw.div`mt-4`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-4 font-medium tracking-wide text-sm text-gray-600 h-auto mb-1`
export default () => {


  return (
    <>
    <a class="anchor" />
    <Container >
      <Content>
        <Row >
          <Description><h5>If you have any questions, feel free to contact us at info@safeharbourtech.com</h5></Description>
        </Row>
      </Content>
      
    </Container>
    </>
  );
};
