import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms and Conditions" }) => {
  return (
    <AnimationRevealPage>
      <Container style={{border: '2px solid white', padding: '10px'}}>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading style={{color: 'white'}}>{headingText}</Heading>
          </HeadingRow>
          <Text>
              <div id="TermsOfService" style={{color:'white'}}>
            <p>Last updated: May 8, 2023</p>

            <p>These Terms and Conditions (the “Terms”) constitute a legal agreement between you and SAFE HARBOUR Research & Technologies Corp. governing the use of our website. Your agreement to comply with and be bound by these terms and conditions is deemed to occur upon your first use of the Website. </p>

            <h1>1. Access to Site Subject to Terms and Conditions</h1>
            <ul>
              <li>Your obligation to comply with all applicable laws and regulations.</li>
              <li>Your access to and use of the services is conditioned on your acceptance of and compliance with all applicable terms.</li>
            </ul>
            <p>
            We reserve the right to change these terms at any time.
            By accessing, browsing and/or using the services after updates to these terms have been posted, you agree to be bound by the updated terms.
            </p>

            <h1>2. General Conditions</h1>

            <p>We do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us.</p>

            <p>We make material changes to these terms and conditions from time to time, The website is licensed to you on a limited, non-exclusive, non-transferable, non-sublicensable basis, solely to be used in connection with the Service for your private, personal, 
              non-commercial use, subject to all the terms and conditions of this Agreement as they apply to the Service.</p>

            <h1>3. License to use website</h1>

            <p>We may provide you with certain information because of your use of the website. Such information may include but is not limited to, documentation, data, or information developed by us, 
              and other materials which may assist in your use of the website.</p>

            <p>Subject to this Agreement, we grant you a non-exclusive, limited, non-transferable, and revocable license to use Our Materials solely in connection with your use of the website. Our Materials may not be used for any other purpose, 
              and this license terminates upon your cessation of use of the website or at the termination of this Agreement.</p>

            <h1>4. Intellectual Property​</h1>
            <p>You agree that the Website and all Services provided by us are the property of SAFE HARBOUR Research & Technologies Corp., including all copyrights, trademarks, trade secrets, patents, and other intellectual property. You agree that we own all rights, title, and interest in and to the Our IP and that you will not use Our IP for any unlawful or infringing purpose. 
              You agree not to reproduce or distribute Our IP in any way, including electronically or via registration of any new trademarks, 
              trade names, service marks, or Uniform Resource Locators, without express written permission from us.</p>

            <h1>5. User Obligations​</h1>

            <p>As a user of the website, you may be asked to supply identifying information to us.</p>
            <p>You may also provide personal information, including, but not limited to, your name.</p>
            <p>You are responsible for ensuring the accuracy of this information. You must not share such identifying information with any third party, and if you discover that your identifying information has been compromised, you agree to notify us immediately in writing. An email notification will suffice. You are responsible for maintaining the safety and security of your identifying information as well as keeping us apprised of any changes to your identifying information. Providing false or inaccurate information 
              or using the website to further fraud or unlawful activity is grounds for immediate termination of this Agreement.</p>

            <h1>6. Reverse engineering and security</h1>
            <p>You agree not to undertake any of the following actions:</p>

            <ul>
              <li>Reverse engineer or attempt to reverse engineer or disassemble any code or software from or on the website.</li>
              <li>Violate the security of the website through any unauthorized access, circumvention of encryption or other security tools, data mining, or interference to any host, user, or network.</li>
            </ul>
            
            <h1>7. Exclusion of liability</h1>
            <p>You understand and agree that we (A) do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties; and (B) shall not be responsible for any materials posted by us or any third party. You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers and any information provided by us or any third party.</p>

            <p>Further, we shall not be liable for direct, indirect consequential, or any other form of loss or damage that may be suffered 
              by a user using the SAFE HARBOUR Research & Technologies Corp. Website including loss of data or information or any kind of financial or physical loss or 
              damage.</p>

            <p>In no event shall SAFE HARBOUR Research & Technologies Corp., nor its Owner, directors, employees, partners, agents, suppliers, or affiliates, be accountable for any indirect, incidental, special, eventful, or exemplary costs, including without limitation, loss of proceeds, figures, usage, goodwill, or other intangible losses, consequential from (i) your use or access of or failure to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content attained from the Service; and (iv) unlawful access, use or alteration of your transmissions or content, whether or not based on guarantee, agreement, domestic wrong (including carelessness) or any other lawful concept, whether or not we’ve been aware of the possibility of such damage, 
              and even if a cure set forth herein is originated to have futile of its important purpose.</p>

            <h1>8. Spam Policy</h1>

            <p>You are strictly prohibited from using the Website or any of our Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.</p>

            <h1>9. Third-party links and content</h1>
            <p>We may occasionally post links to third-party websites or other services. You agree that we are not responsible for any loss or damage caused because of your use of any third-party services linked to or from Our Website.</p>

            <h1>10. Modification and variation</h1>

            <p>We may, from time to time and at any time without notice to you, modify this Agreement. You agree that we have the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement unless prior versions are specifically referred to or 
              incorporated into the latest modification or variation of this Agreement.</p>

            <h1>11. Entire agreement</h1>
            <p>This Agreement constitutes the entire understanding between the Parties concerning any use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.</p>

            <h1>12. Service interruptions</h1>
            <p>We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that 
              we shall have no liability for any damage or loss caused because of such downtime.</p>

            <h1>13. Term, Termination and Suspension</h1>
            <p>We may terminate this Agreement with you at any time for any reason, with or without cause. We specifically reserve the right to terminate this Agreement if you violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of us or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. At the termination of this Agreement, any provisions 
              that would be expected to survive termination by their nature shall remain in full force and effect.</p>

            <h1>14. General provisions</h1>
            <p>This Agreement, or the rights granted hereunder, may not be assigned, sold, leased, or otherwise transferred in whole or part by you. Should this Agreement, or the rights granted hereunder, be assigned, sold, leased, or otherwise transferred by us, the rights, and liabilities of SAFE HARBOUR Research & Technologies Corp. will bind and inure to any assignees, 
              administrators, successors, and executors.</p>

            <p>If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such a condition, the remainder of this Agreement shall continue in full force.</p>

            <p>If we fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part.</p>

            <p>Headings of parts and sub-parts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement.</p>

            <p>No agency, partnership, or joint venture has been created between the Parties because of this Agreement. No Party has any authority to bind the other to third parties.</p>

            <p>We are not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature, and natural disasters, and other acts which may be due to unforeseen circumstances.</p>

            <p>The terms herein will be governed by and construed by the laws of Canada without giving effect to any principles of conflicts of law. The Courts of Canada shall have exclusive jurisdiction over any dispute arising from the use of the Website.</p>

            <h1>15. Governing Laws</h1>
            <p>The Terms and Conditions are governed by the laws of the Province of Nova Scotia and the laws of Canada. These laws govern the use of the Site and its contents by you, regardless of your location, domicile, or residency. This Site is intended for use only in those jurisdictions where it may be lawfully offered for use.</p>
            </div>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
