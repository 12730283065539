import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import pic1_assessment from "../../images/temp/research.jpg";
import pic2_project from "../../images/temp/meeting.jpg";
import pic3_deployment from "../../images/temp/maintenance.jpg";
import { SectionDescription } from "components/misc/Typography.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-12`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;
const Description = tw(SectionDescription)`max-w-screen-lg  text-center`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded`
]);
const Title = tw.div`mt-4 text-secondary-100 text-lg`;
const Category = tw.h4`mt-2 leading-relaxed font-bold text-lg`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const blogPosts = [
    {
      imageSrc: pic1_assessment,
      category: "Research & Harbour Assessment",
      title: "The SAFE HARBOUR team meets with all stakeholders to determine the scope of the project. We provide a report with recommendations for technological and other solutions for your unique port or waterway.",
      // url: "http://www.safeharbourtech.com/"
    },
    {
      imageSrc: pic2_project,
      category: "The Project",
      title: "SAFE HARBOUR meets with stakeholders, provides a budget and implements the greenlit plan.",
      // url: "http://www.safeharbourtech.com/"
    },
    {
      imageSrc: pic3_deployment,
      category: "Deployment & Maintenance",
      title: "The SAFE HARBOUR team deploys and maintains technological equipment. We also provide the option of training your team so future activiites may be done  in-house. ",
      // url: "http://www.safeharbourtech.com/"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>We are seeking strategic partners in the clean technology and ocean technology space.</HeadingTitle>
          <Description>Does your company or startup offer state of the art sensors or other technologies which may benefit harbours, ports and other waterways? Do you offer proven, cutting edge or emerging technologies? 
            Do you have stand-alone, sustainable units that can handle harsh marine environments?  
            Is your technology plug and play? Are we able to harvest and upload real-time data to the cloud?  Do you have an user-friendly interface for multiple stakeholders?  <br/><br/>
           We would love to have a conversation.</Description>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
