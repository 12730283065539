import React from "react";
import tw from "twin.macro";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import { SectionDescription } from "components/misc/Typography.js";

import partners from "../../images/safeHarbour/Partners/temp_partners.jpg"
import './About.css';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pt-20 lg:pt-24`;
const Description = tw(SectionDescription)`max-w-screen-lg  text-center`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

export default () => {
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>About the Company</HeadingTitle>
          <Description>We develop autonomous smart buoys equipped with state-of-the-art sensors and emerging technologies.<br/>
          Our mission is to keep people, property and marine life SAFE in ports, harbours, rivers, canals and marinas; SAFE HARBOUR tools will be invaluable for all stakeholders involved in multi-use waterway and ecosystem management.<br/><br/>
          <strong>We are seeking multi-use waterway management and port authorities for pilot runs of our prototype in 2024.</strong>
          </Description>
        </HeadingInfoContainer>
        {/* <div class="flex-container">
          <img id="partners" src={partners}></img>
        </div> */}

      </Content>
    </Container>
  );
};
