import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, { useState, useRef } from 'react';
import logo from "./logominitextless.png";
import './NavigationBar.css';

function NavigationBar({faq, about, mission, partners, consultation, email, isActive}) {
    const [isOpen, setIsOpen] = useState(false);

    
      const goTo = (reference) => {
        window.scrollTo({
          top: reference.current.offsetTop - 50,
          behavior: 'smooth',
        });
      };
    
    //   const handleMenuClick = (e) => {
    //     e.stopPropagation();
    //     setIsOpen(prevState => !prevState);
    //   };
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top" style={{ backgroundColor: "#001b6e"}}>
      <Container>
        <Navbar.Brand href="#home">
            <div className='logo-div'>
                      <img
                          src={logo}
                          width="80"
                          height="80"
                          className="d-inline-block align-top"
                          alt="Safe Harbour logo"
                      />
                      <div id="logoAreaInner">
                          <h1 id="logohone">Safe Harbour</h1>
                          <h4>Research & Technologies</h4>
                      </div>
                  </div>
            </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="me-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
          <Nav className='ms-auto' >
            <Nav.Link className='px-3' href="#about" onClick={(e)=> {e.preventDefault(); goTo(about)}}>About Us</Nav.Link>
            <Nav.Link className='px-3'href="#partners" onClick={(e)=> {e.preventDefault(); goTo(partners)}}>Partners</Nav.Link>
            <Nav.Link className='px-3'href="#mission" onClick={(e)=> {e.preventDefault(); goTo(mission)}}>Mission</Nav.Link>
            <Nav.Link className='px-3' href="#faq" onClick={(e)=> {e.preventDefault(); goTo(faq)}}>FAQ</Nav.Link>
            <Nav.Link className='px-3'href="#email" onClick={(e)=> {e.preventDefault(); goTo(email)}}>Contact Us</Nav.Link>
            <Nav.Link className='px-3' href="#consultation" onClick={(e)=> {e.preventDefault(); goTo(consultation)}}>Free Consultation</Nav.Link>
         
            {/* <Nav.Link eventKey={2} href="#memes"> Mission </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;